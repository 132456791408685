import {NavBar, Footer} from '@tendy/react';
import type {LoaderArgs} from '@remix-run/cloudflare';
import {redirect} from '@remix-run/cloudflare';

export const loader = async ({context, request}: LoaderArgs) => {
  // try {
  //   if (request.headers.has('Cookie')) {
  //     console.log(`cookie found ${request.headers.get('Cookie')}`);
  //     context.pb.authStore.loadFromCookie(request.headers.get('Cookie') || '');
  //   } else {
  //     await context.pb
  //       .collection('users')
  //       .authWithPassword('micah@email.com', 'tI13!@c6pNbg');
  //     let cookie = context.pb.authStore.exportToCookie();
  //     return redirect('/dash', {headers: {'Set-Cookie': cookie}});
  //   }

  //   let res = await context.pb.collection('user_integrations').getList();
  //   console.log(res);
  // } catch (error) {
  //   console.log(error);
  // }
  return null;
};

export default function IndexRoute() {
  return (
    <>
      <NavBar />
      <div className="content-center">
        <div className="hero min-h-screen bg-base-200">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold">Hello there</h1>
              <p className="py-6">
                Provident cupiditate voluptatem et in. Quaerat fugiat ut
                assumenda excepturi exercitationem quasi. In deleniti eaque aut
                repudiandae et a id nisi.
              </p>
              <button className="btn btn-primary">Get Started</button>
            </div>
          </div>
        </div>
        <div className="hero min-h-screen bg-base-200">
          <div className="hero-content text-center">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold">Hello there</h1>
              <p className="py-6">
                Provident cupiditate voluptatem et in. Quaerat fugiat ut
                assumenda excepturi exercitationem quasi. In deleniti eaque aut
                repudiandae et a id nisi.
              </p>
              <button className="btn btn-secondary">Get Started</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
